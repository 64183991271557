import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';

import './App.css';
import { Peer } from "peerjs";
import { styled } from '@mui/material/styles';
import {Box, CircularProgress, Typography, IconButton, Paper, Button, Stack} from '@mui/material';
import CallEndIcon from '@mui/icons-material/CallEnd';

import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';

import useMediaRecorder from '@wmik/use-media-recorder';

// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, onSnapshot, setDoc, doc } from "firebase/firestore";

import LoadingModal from '../../../components/settings/LoadingModal';
import {isAuthenticated, hostId, temiId} from '../../../utils/session';
import {getDateTimeForFile} from '../../../utils/ext'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const DATABASE_URL = 'videoCalls'
const MAX_TIME_DIFF = 5000;
const downloadRecordingType = 'mkv'
const downloadRecordingPath = 'Call'

const firebaseConfig = {
  apiKey: "AIzaSyChlS1r6aURBE0u3V7DS6Q3CHY78S8LidU",
  authDomain: "miroboticproject.firebaseapp.com",
  databaseURL: "https://miroboticproject.firebaseio.com",
  projectId: "miroboticproject",
  storageBucket: "miroboticproject.appspot.com",
  messagingSenderId: "387151318489",
  appId: "1:387151318489:web:ce680b2b3088870ddc1dc8",
  measurementId: "G-G5RCBZNVQ2"
};


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));


// Initialize Firebase
const app = initializeApp(firebaseConfig);

function Player({ mediaBlob, audio }) {
  if (!mediaBlob) {
    return null;
  }

  if (audio) {
    return <audio src={URL.createObjectURL(mediaBlob)} controls />;
  }

  return (
    <video
      src={URL.createObjectURL(mediaBlob)}
      width={520}
      height={480}
      controls
    />
  );
}

function App() {

  const peerClient = useRef();
  const localVideo = useRef();
  const remoteVideo = useRef();
  const localStream = useRef();
  const database = useRef();
  const lastStatus = useRef('');
  const downloadFile = useRef(0);
  const recordingStarted = useRef(false);
  const callEnded = useRef(false);
  const endCallRef = useRef(null)
  const recordingNumber = useRef(getDateTimeForFile());
  const [callStatus, setCallStatus] = useState(false);
  const [hideVideo, setHideVideo] = useState(true);
  const [muteAudio, setMuteAudio] = useState(true);
  const [initApp, setInitApp] = useState(true);
  const [showProgress, setShowProgress] = useState(null);
  const sbsHostId = useRef(hostId())
  const sbsTemiId = useRef(temiId())

    const {
      error,
      status,
      mediaBlob,
      stopRecording,
      getMediaStream,
      unMuteAudio,
      startRecording,
      isAudioMuted
    } = useMediaRecorder({
      recordScreen: true,
      blobOptions: { type: 'video/webm;codecs=vp8,opus' },
      mediaStreamConstraints: { audio: true, video: true, preferCurrentTab: true }
    });
    
  const navigate = useNavigate();

  if(!isAuthenticated()){
    return <Navigate to='/login'  />
  }

  const viewRecording = () => {
    window.open(mediaBlob, "_blank").focus();
  };

  useEffect(() => {

      console.warn('video status', status, sbsHostId.current, sbsTemiId.current);
      if(status === "recording") {
        recordingStarted.current = true;
      }else if(status === 'ideal') {
        getMediaStream();
      }
  }, [status])

  async function disconnectCall() {

    console.warn('disconnectCall', sbsTemiId.current);
    try {
      const db = getFirestore();
      const docRef = setDoc(doc(db, DATABASE_URL, sbsHostId.current), {
        incoming: sbsTemiId.current,
        isAvailable: true,
        connId: sbsTemiId.current,
        status: 'disconnect',
        time: new Date().getTime(),
        user: sbsHostId.current
      });
      console.log("Document written with ID: ", peerClient.current);
    } catch (e) {
      console.error("Error adding document: ", e);
    }

  }

  const downloadRecording = () => {

    console.error('downloadFile', downloadFile.current, recordingStarted.current, callEnded.current);
    // if(downloadFile.current > 2) {
    //   return
    // }

    if(!recordingStarted.current) {
      navigate("/dashboard/app");
      window.location.reload();
    }

    setShowProgress('Preparing recording');

    if(!callEnded.current) {
      callEnded.current = true;
      disconnectCall()
    }

    if(status !== 'stopped' ) {
      stopRecording()
    }

    downloadFile.current += 1;

    setTimeout(() => {
      console.error('mediaBlob', mediaBlob);
      if(mediaBlob == null) {
        setTimeout(() => {
          endCallRef.current.click()
        }, 3000)
        return
      }
      const fileName = `${downloadRecordingPath}_${recordingNumber.current}.${downloadRecordingType}`;
      const filePath = URL.createObjectURL(mediaBlob);
      console.error('filePath', filePath, fileName);
      try {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // for IE
          window.navigator.msSaveOrOpenBlob(filePath, fileName);
        } else {
          // for Chrome
          const link = document.createElement("a");
          link.href = filePath;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        navigate("/dashboard/app");
        window.location.reload();
      } catch (err) {
        console.error(err);
      }
    }, 3000)
 };

  useEffect(() => {
    if(!initApp) {
      return
    }
    database.current = getFirestore(app);
    setInitApp(false);
    const db = getFirestore();
    const unsub = onSnapshot(doc(db, DATABASE_URL, sbsHostId.current), (doc) => {
      try{
        const data = doc.data();

        if(lastStatus.current === data.status) {
          return
        }
        const time = new Date().getTime();
        const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
        console.log('source', source);
        const diff = time - data.time;
        console.log("Current time: ", time, data.time);
        console.log("Current diff: ", diff);
        console.log("Current data: ", data);
        if(diff < MAX_TIME_DIFF) {
          console.log("Current status: ", lastStatus.current, data.status);
   
          lastStatus.current = data.status;
          if(data.status === 'disconnect') {
            console.warn("DISCONNECT CALL", peerClient.current);
            peerClient.current?._socket.close();
            if(!callEnded.current) {
              callEnded.current = true;
              endCallRef.current.click()
            }
          }
        }
      }catch(e) {
        console.error("Data error: ", e);
      }
    });

    setTimeout(() => {
      registerUser()
    }, 1000)
    setTimeout(() => {
      startCall()
    }, 3000)
  }, [initApp])

  async function updateUserStatusFirebase() {

    console.log('updateUserStatusFirebase', sbsTemiId.current);
    try {
      const db = getFirestore();
      const docRef = setDoc(doc(db, DATABASE_URL, sbsHostId.current), {
        incoming: sbsTemiId.current,
        isAvailable: true,
        connId: sbsTemiId.current,
        status: 'connected',
        time: new Date().getTime(),
        user: sbsHostId.current
      });
      console.log("Document written with ID: ", docRef);
    } catch (e) {
      console.error("Error adding document: ", e);
    }

  }

  async function registerFirebase() {

    console.log('registerFirebase', sbsHostId.current);

    const db = getFirestore();
    try {
      const docRef =  setDoc(doc(db, DATABASE_URL, sbsHostId.current), {
        incoming: "",
        isAvailable: false,
        connId: "",
        time: new Date().getTime(),
        user: sbsHostId.current
      });
      console.log("Document written with ID: ", docRef);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  const registerUser = () => {
    console.log('registerUser', sbsHostId.current);

    console.log('registerUser', sbsHostId.current);

    peerClient.current = new Peer(sbsHostId.current, {
      host: 'video.mirobotic.tech',
      port: 8010,
      path: '/videocallapp',
      secure: true
    })

    listen();

    registerFirebase();
  }

  const startCall = () => {
    console.log('startCall', sbsTemiId.current, isAudioMuted);

    updateUserStatusFirebase();
    // unMuteAudio(true);
    // startRecording();

    navigator.getUserMedia({
      audio: true,
      video: true
  }, (stream) => {

      if(!stream) {
        console.error('stream is null')
        return
      }


      console.log('stream ', stream);
      localVideo.current.srcObject = stream;
      localStream.current = stream;

      const call = peerClient.current.call(sbsTemiId.current, stream)
      call?.on('stream', (remoteStream) => {
          remoteVideo.current.srcObject = remoteStream
          remoteVideo.current.className = "primary-video"
          localVideo.current.className = "secondary-video"
          setCallStatus(true);
      })

  }, (error) => {})
  }

  function listen() {
          peerClient.current.on('call', (call) => {

          navigator.getUserMedia({
              audio: true, 
              video: true
          }, (stream) => {
              localVideo.current.srcObject = stream
              localStream.current = stream

              console.log('stream', stream);
              call.answer(stream)
              call.on('stream', (remoteStream) => {                
                  remoteVideo.current.srcObject = remoteStream
                  remoteVideo.current.className = "primary-video"
                  localVideo.current.className = "secondary-video"
                  setCallStatus(true);
              })
          }, (error) => {})
          
      })

      peerClient.current.on("disconnect", (reason)=>{
        console.log('server disconnect')
      });

      peerClient.current.on("close", (reason)=>{
        console.log('server close')
      });

      peerClient.current.on("user-disconnected", (userId)=>{
        // remove video or add your code here
        console.log('user disconnect')
      });

      
  }

  function toggleVideo(b) {
    console.log('toggleVideo', b);
    if (b) {
        localStream.current.getVideoTracks()[0].enabled = true
    } else {
        localStream.current.getVideoTracks()[0].enabled = false
    }
    setHideVideo(b);
  } 

  function toggleAudio(b) {
    console.log('toggleAudio', b);
    if (b) {
        localStream.current.getAudioTracks()[0].enabled = true
    } else {
        localStream.current.getAudioTracks()[0].enabled = false
    }
    setMuteAudio(b);
  } 

  const AudioStatusView = () => {
    console.log('AudioStatusView', muteAudio);
    if(!muteAudio) {
      return (
        <IconButton aria-label="audio-off" onClick={() => toggleAudio(true)}>
          <MicOffIcon />
        </IconButton>
      )
    }

    return (
      <IconButton color='primary' variant="contained" aria-label="audio-on" onClick={() => toggleAudio(false)}>
        <MicIcon />
      </IconButton>
    )
  }

  const VideoStatusView = () => {
    console.log('VideoStatusView', hideVideo);
    if(!hideVideo) {
      return (
        <IconButton aria-label="video-off" onClick={() => toggleVideo(true)}>
          <VideocamOffIcon />
        </IconButton>
      )
    }

    return (
      <IconButton color='primary' variant="contained" aria-label="video-on" onClick={() => toggleVideo(false)}>
        <VideocamIcon />
      </IconButton>
    )
  }

  const CallStatusView = () => {

    if(callStatus) {
      return (
        <Box direction="row">
            <Button ref={endCallRef} onClick={() => {downloadRecording()}} color='error' variant="outlined" startIcon={<CallEndIcon />}>
                End Call
            </Button>
            <AudioStatusView sx={{mt: 1}} />
            <VideoStatusView />
        </Box>
      )
    }

    return <CircularProgress />
  }

  return (
    <div className="App Scren-Record-Wrapper">
    <LoadingModal
        title={showProgress} />
      <Box>
        <Stack>
          <Item>
            <CallStatusView />
          </Item>
          <Item>
            <Box
              sx={{
                width: 800,
                height: 500,
                backgroundColor: 'primary.dark',
                '&:hover': {
                  backgroundColor: 'primary.main',
                  opacity: [0.9, 0.8, 0.7],
                },
              }}>
              <video className="secondary-video" ref={remoteVideo} autoPlay id="remote-video"></video>
              <video className="primary-video" ref={localVideo} autoPlay muted id="local-video"></video>
            </Box>
          </Item>
        </Stack>
      </Box>
    </div>
  );
}

export default App