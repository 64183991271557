import * as React from 'react';
import { useForm } from "react-hook-form";
import validator from 'validator'

import {
    styled,
    Box,
    Grid,
    Card,
    Stack,
    Button,
    Container,
    Typography,
    IconButton,
    MenuItem,
    FormControl,
    FormControlLabel,
    Select,
    Avatar,
    CssBaseline,
    TextField,
    Link,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    FormLabel,
    InputLabel,
    OutlinedInput,
    Radio,      
  } from '@mui/material';
  import { AudioCard, VideoCard } from 'material-ui-player'

import dayjs from 'dayjs';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ReactPlayer from 'react-player'

import { ErrorMessage } from '@hookform/error-message';
import { apiCallPostWithForm, apiPostSecure, apiUrl } from '../../../../utils/api';
import { checkIsBefore, getTimePlusMins, getTimePlusHours, checkTimeIsBefore, getDatePlusHours,  dateToShowDateFormat, dateFromFromServer, timeFromFromServer, isBeforeToday} from '../../../../utils/ext'

const Input = styled('input')({
    display: 'none',
  });


  const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

  
export const AddScheduleModal = (props) => {

    const { show, change, onSaveChange, locationList } = props;

    const [addApiCalling, setAddApiCalling] = React.useState(false);
    const [selectedLocation, setSelectedLocation] = React.useState(null);

    console.log('locationList', locationList.current);

    const handleChange = (event) => {
        setSelectedLocation(event.target.value);
    };

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }
        reset({
            id:null,
            text: "",
          }
        );
        setSelectedLocation(null);
        change(false);
    }

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        clearErrors,
        reset,
        formState: { errors },
      } = useForm();

    React.useEffect(() => {
        if(show) {
            
            setValue('time_start', getTimePlusMins(2))
            setValue('time_end', getTimePlusHours(3))
    }
}, [show])
    const saveData = (data) => {

        setTimeout(() => {
            clearErrors();
        }, 3000);

        console.log('data', data);

        if(addApiCalling) {
            return
        }

        if(selectedLocation === null) {
            setError('location', {
                type: "server",
                message: 'Select location.',
            });
            return
        }

        const isValidDates = checkTimeIsBefore(data.time_start, data.time_end);
        console.log('checkTimeIsBefore', isValidDates)        

        if(!isValidDates) {
            setError('time_end', {
                type: "server",
                message: 'End time must be before start time.',
            });
            return
        }

        setAddApiCalling(true)

        const body = {
            time_start: `${data.time_start}:00`,
            time_end: `${data.time_end}:00`,
            location: selectedLocation,
        } 

        onSaveChange(0, "Adding schedule...");

        apiPostSecure(apiUrl.schedule, body,
            (response) => {
                setAddApiCalling(false)
                onSaveChange(1, response.msg);
                handleClose();
           },
           (errorMsg) => {
                setAddApiCalling(false)
                onSaveChange(2,  errorMsg||'Error');
                console.log('add-schedule', errorMsg||'Error');
           }
          )

    } 

    return (
        <Dialog open={show} onClose={handleClose}>
        <DialogTitle>Add Schedule</DialogTitle>
        <DialogContent>
            <form id="save-form" onSubmit={handleSubmit(saveData)}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='body'>Location</Typography>
                                <FormControl sx={{ width: '100%', mt:2 }}>
                                    <InputLabel id="demo-multiple-checkbox-label">Select location</InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        value={selectedLocation}
                                        onChange={handleChange}
                                        input={<OutlinedInput label="Select location" />}
                                        MenuProps={MenuProps}
                                        >
                                        {locationList.current?.map((item) => (
                                            <MenuItem value={item}>{item}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                    {errors.location && <p>{errors.location.message}</p>}     
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={3}>
                                    <Typography variant='body'>Start Time</Typography>
                                    <TextField type="time" placeholder="Start Time"  {...register("time_start")} /> 
                                    {errors.time_start && <p>{errors.time_start.message}</p>}     
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={3}>
                                    <Typography variant='body'>End Time</Typography>
                                    <TextField type="time" placeholder="End Time"  {...register("time_end")} /> 
                                    {errors.time_end && <p>{errors.time_end.message}</p>}     
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <ErrorMessage
                                    errors={errors}
                                    name="singleErrorInput"
                                    render={({ message }) => <p>{message}</p>}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    </Box>
                </Container>
            </form>  
        </DialogContent>
        <DialogActions>
            <Button variant="outlined" onClick={() => handleClose()}>Close</Button>
            <Button variant="contained" type='submit'  form="save-form">Save</Button>
        </DialogActions>
    </Dialog>
    )
  }

export default AddScheduleModal;
