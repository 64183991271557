import * as React from 'react';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Edit from '@mui/icons-material/Edit';
import Info from '@mui/icons-material/Info';
import Delete from '@mui/icons-material/Delete';
import Visibility from '@mui/icons-material/Visibility';
import { Grid, Tooltip, IconButton, Stack, Avatar, Box } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import VideoIcon from '../../../../assets/video-player.png'
import {apiUrl} from '../../../../utils/api'
import {isBeforeToday} from '../../../../utils/ext'

const GetExpiryStatus = ({from, to}) => {

  if(!from || !to) {
    return null
  }

  if(!isBeforeToday(from)) {
    return <Typography sx={{m: 1}} variant='subtitle' color='primary'>Upcoming</Typography>
  }

  if(!isBeforeToday(to)) {
    return <Typography sx={{m: 1}} variant='subtitle' color='#0a0'>Active</Typography>
  }

  return <Typography sx={{m: 1}} variant='subtitle' color='error'>Expired</Typography>
}

const getText = (text) => {
  if(text) {
    if(text.length > 55) {
      return `${text.substring(0, 55)}...`
    }
    return text;
  }
  return '------'
}


const GetContent = ({data}) => {
    return (
      <Box>
          <Typography sx={{height: '60px'}} >{getText(data.text)}</Typography>
          <Typography variant='subtitle1'>Bus:</Typography>
          <Typography variant='h6'>{data.bus}</Typography>
          <Typography variant='subtitle1'>Berth:</Typography>
          <Typography variant='h6'>{data.berth}</Typography>
      </Box>
    )
}


export default function DelayCard({data, role, onInfo, onDelete}) {

  const GetActions = () => {

    if(role.current === 'A' || role.current === 'ST') {
      return (
        <Stack 
            spacing={2}
          sx={{width:'100%'}}
          justifyContent="center"
          alignItems="center"
          direction="row"> 
          <GetExpiryStatus from={data.time_start} to={data.time_end} />
          <Tooltip title="View" sx={{m: 2}}>
            <Avatar sx={{ bgcolor: '#20499B' }}>
              <IconButton sx={{ color: '#fff' }}  variant="contained" size="small" onClick={() => onInfo(data)}>
                <Visibility />
              </IconButton>
            </Avatar>        
          </Tooltip>
          <Tooltip title="Delete" sx={{m: 2}}>
            <Avatar sx={{ bgcolor: '#FF4646' }}>
              <IconButton sx={{ color: '#fff' }}  variant="contained" size="small" onClick={() => onDelete(data)}>
                <Delete />
              </IconButton>
            </Avatar>            
          </Tooltip>
        </Stack>
      )
    }
    return (
      <Stack 
      sx={{width:'100%'}}
      justifyContent="center"
      alignItems="center"
      direction="row"> 
      <Tooltip title="View" sx={{mx: 1}}>
          <Avatar sx={{ bgcolor: '#20499B' }}>
            <IconButton sx={{ color: '#fff' }}  variant="contained" size="small"  onClick={() => onInfo(data)}>
              <Visibility />
            </IconButton>
          </Avatar>        
        </Tooltip>
      </Stack>
    )
  }


  return (
    <Card  sx={{ boxShadow: 1}} spacing={2}>
      <CardContent sx={{height: '250px'}}>
        <GetContent data={data} />
      </CardContent>
      <CardActions sx={{rightAlignItem: {marginLeft: "auto"}}}>
        <GetActions />
      </CardActions>
    </Card>
  );
}