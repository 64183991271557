import React, { useState, useEffect } from 'react';

import { useForm, Controller } from "react-hook-form";

// material
import { Link, Stack, Box, TextField, IconButton, InputAdornment, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import { logout, saveUser } from "../../../../utils/session";
import { apiUrl, apiPostUnsecure, uploadFile } from "../../../../utils/api";
import { showError, showSucess } from '../../../../utils/toast';

// ----------------------------------------------------------------------

export default function RegisterForm() {
 
  const [isLoading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const [error, setError] = useState("");

  async function sginUp(data) {
    setLoading(true);
    apiPostUnsecure(
      apiUrl.signUp,
      data,
      (response) => {
        setLoading(false);
        showSucess(response.msg);
        setValue('firm', null);
        setValue('first_name', null);
        setValue('last_name', null);
        setValue('email', null);
        setValue('mobile', null);
        setValue('address', null);
      },
      (errorMsg) => {
        setLoading(false);
        console.log('error', errorMsg)
        showError(errorMsg);
      }
    );
  }


  return (
    <Box>
      <form id="hook-form" onSubmit={handleSubmit(sginUp)}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="firm"
            type="text"
            label="Business name"
            {...register("firm", { required: "Enter business name" })}
          />

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...register("first_name", { required: "Enter first name" })}
            />

            <TextField
              fullWidth
              label="Last name"
              {...register("last_name", { required: "Enter last name" })}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...register("email", { required: "Enter email" })}
          />

          <TextField
            fullWidth
            autoComplete="mobile"
            type="phone"
            label="Mobile"
            {...register("mobile", { required: "Enter mobile" })}
          />
          <TextField
            fullWidth
            autoComplete="address"
            type="address"
            label="Address"
            {...register("address", { required: "Enter address" })}
          />

          <LoadingButton fullWidth size="large"  form="hook-form" type="submit" variant="contained" loading={isLoading}>
            Register
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
}
