import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import MUIDataTable from 'mui-datatables';
import Tooltip from '@mui/material/Tooltip';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  CardContent,
  CardMedia,
  CardActions ,
  IconButton,
  Grid,  
  Box,
} from '@mui/material';


import SBSLogo from '../../../assets/sbs_logo.png'
import LoadingModal from '../../../components/settings/LoadingModal';
import Page from '../../../components/Page';
import ChangePasswordModal from './modals/ChangePassword'
import { showError, showSucess } from '../../../utils/toast';
import { getUser } from '../../../utils/session'

const CardInfo = ({list}) => {
  const data = []
  list.forEach(
    (item) => {
      data.push(
        <Box>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" >
            {item.title}
          </Typography>
          <Typography variant="h6" gutterBottom>
            {item.text}
          </Typography>
        </Box>
      )
    }
  );

  return (
    <Card sx={{ mt:2 }}>
      <CardContent>
        {data}
      </CardContent>
  </Card>
  )
}

const AccountPage = () => {
  
  const navigate = useNavigate();

  const userAccount = useRef(getUser())
  const [showChangePassword, setShowChangePassword] = useState(false)
  const [loadingTitle, setLoadingTitle] = useState(null);
  
  const onChangePasswordListener = (status, message) => {
    if(status === 1) {
      setLoadingTitle(null);
      showSucess(message);
    }else if(status === 2) {
      setLoadingTitle(null);
      showError(message)
    }else {
      setLoadingTitle(message);
    }
}

  const ProfileHeader = () => {
      return (
          <Card sx={{p:2}}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                  {userAccount.current?.company}
                </Typography>
                <Box component="img" src={SBSLogo} sx={{ width: 200,  }} />
              </Stack>
        </Card>
      )
  }

  const PersonalInfo = () => {

    const list = [];
    list.push(
      {
        title: 'Name',
        text: `${userAccount.current?.firstName} ${userAccount.current?.lastName}`,
      }
    )
    list.push(
      {
        title: 'Email',
        text: userAccount.current?.email,
      }
    )
    list.push(
      {
        title: 'Contact',
        text: userAccount.current?.mobile,
      }
    )
    list.push(
      {
        title: 'Address',
        text: userAccount.current?.address,
      }
    )

    return <CardInfo list={list} />
  }

  const logout = () => {
    navigate('/login', { replace: true });
  }

  const ActionButtions = () => {
    return (
      <Card sx={{ mt:2 }}>
        <CardContent>
          <Stack spacing={2}>
            <Button variant="outlined" onClick={() => setShowChangePassword(true)}>Change password</Button>
            <Button variant="outlined" onClick={() => logout()}>Logout</Button>
          </Stack>
        </CardContent>
      </Card>
    )
  }

  return (
    <Page title="Account">
      <Container>
      <LoadingModal
          title={loadingTitle} />
        <ChangePasswordModal show={showChangePassword} change={setShowChangePassword} onSaveChange={onChangePasswordListener} onError={showError} />
        <Typography sx={{m:1}} variant='h3'>Account</Typography>
        <ProfileHeader />
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <PersonalInfo/>
          </Grid>
          <Grid item xs={4}>
            <ActionButtions/>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AccountPage;

