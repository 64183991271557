import * as React from 'react';
import { useForm } from "react-hook-form";
import validator from 'validator'

import {
    styled,
    Box,
    Grid,
    Card,
    Stack,
    Button,
    Container,
    Typography,
    IconButton,
    MenuItem,
    FormControl,
    FormControlLabel,
    Select,
    Avatar,
    CssBaseline,
    TextField,
    Link,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    FormLabel,
    InputLabel,
    RadioGroup,
    Radio,      
  } from '@mui/material';
  import { AudioCard, VideoCard } from 'material-ui-player'

import dayjs from 'dayjs';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ReactPlayer from 'react-player'

import { ErrorMessage } from '@hookform/error-message';
import { apiCallPostWithForm, apiPostSecure, apiUrl } from '../../../../utils/api';
import { checkIsBefore, getTimePlusMins, getTimePlusHours, getDatePlusMins, getDatePlusHours,  dateToShowDateFormat, dateFromFromServer, timeFromFromServer, isBeforeToday} from '../../../../utils/ext'

const Input = styled('input')({
    display: 'none',
  });

  const GetImage = ({data}) => {
      if(!data.photo) {
          return null;
      }
  
      return (
          <Box>
              <img src={`${apiUrl.announceMedia}${data.photo}`} height='360px'/>
          </Box>
      )
  }
  
  const GetText = ({data}) => {
      return (
            <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
        >
                <GetImage data={data} />
                <Typography>{data.text}</Typography>
            </Grid>
      )
  }
  
  const GetVideo = ({data}) => {
      return (
          <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
                <video width="640px" height="360px" controls>
                    <source src={`${apiUrl.announceMedia}${data.video}`}  type="video/mp4" />                    
                    <track default/>
                </video>
          </Grid>
      )
  }
  
  const GetContent = ({state}) => {
      const data = state.current
      if(data == null) {
        return
      }

      if(data.video) {
          return <GetVideo data={data}/>
      }
  
      return <GetText data={data} />
  
  }
  

export const GreetingInfoModal = (props) => {

    const { show, change, state } = props;
    const [scroll, setScroll] = React.useState('paper');

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }
        state.current = null;
        change(false);
    }

    return (
        <Dialog 
            open={show} 
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            fullWidth='true'
            maxWidth='md'
            >
        <DialogTitle id="scroll-dialog-title">Greeting</DialogTitle>
        <DialogContent>
            <GetContent state={state} />
        </DialogContent>
        <DialogActions>
            <Button variant="outlined" onClick={() => change(false)}>Close</Button>
        </DialogActions>
    </Dialog>
    )
  }  

const AddGreetingsModal = (props) => {

    const { show, change, state, onSaveChange, dates, onError } = props;

    const [addApiCalling, setAddApiCalling] = React.useState(false);
    const [mediaType, setMediaType] = React.useState('T');

    const [mediaImage, setMediaImage] = React.useState(null);
    const [mediaVideo, setMediaVideo] = React.useState(null);

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }
        reset({
            text: "",
            time_start: null,
            time_end: null,
          }
        );
        setMediaType('T');
        setMediaImage(null);
        setMediaVideo(null);
        state.current = null;
        if(show) {
            change(false);
        }
    }

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        reset,
        setValue,
        formState: { errors },
      } = useForm();
    
    const showFormError = (name, error) => {
        setError(name, {
            type: "server",
            message: error
        });
        setTimeout(() => {
            clearErrors();
        }, 3000);
    }  

    const saveData = (data) => {

        setTimeout(() => {
            clearErrors();
        }, 3000);

        console.log('data', data);

        if(addApiCalling) {
            return
        }

        const formData = new FormData();

        if(mediaType === 'T') {
            if(data.text.length < 10) {
                showFormError('text', 'Please enter at least 10 characters')      
                return        
            }

            formData.append("text", data.text);
            if(mediaImage !== null) {
                formData.append("photo", mediaImage);
            }
            console.log('photo', mediaImage);
        }else {
            if(mediaVideo === null) {
                showFormError('video', 'Please select video file')      
                return    
            }
            console.log('video', mediaVideo);
            formData.append("video", mediaVideo);
        }
        
        if(data.id) {
          formData.append("id", state.current?.id);
        }
  
        setAddApiCalling(true)

        onSaveChange(0, "Saving greeting...");

        apiCallPostWithForm(apiUrl.greeting, formData,
            (response) => {
                setAddApiCalling(false)
                onSaveChange(1, response.msg);
                handleClose()
           },
           (errorMsg) => {
                setAddApiCalling(false)
                onSaveChange(2,  errorMsg||'Error');
                console.log('add-bus', errorMsg||'Error');
           }
          )
    } 


    const handleChangeMediaType = (event) => {
        setMediaType(event.target.value);
        console.log('media', mediaType)
    };   


    
    const onImageSelect = (value) => {
        console.log('logo', value);
        const file = value.target.files[0];
        if (file && !file.name) {
            showFormError('photo', 'Please select image file.');
           return false;
        }
        console.log('logo size', file.size);

        if (file.size > 1e6) {
            showFormError('photo', 'Please upload a image smaller than 1 MB');
            return false;
        }
        setMediaImage(file)
    }

    const onVideoSelect = (value) => {
        console.log('logo', value);
        const file = value.target.files[0];
        if (file && !file.name) {
            showFormError('video', 'Please select video file.');
           return false;
        }
        console.log('logo size', file.size);

        if (file.size > 20e6) {
            showFormError('video', 'Please upload a video smaller than 20 MB');
            return false;
        }
        setMediaVideo(file)
    }

    const GetImageMedia = () => {
        if(mediaImage) {
            return (
                <Stack sx={{mt:2}} direction="row-reverse" alignItems="left" spacing={2}>
                    <Typography sx={{width: '100%',  mt: 1}} variant='body'>{mediaImage?.name}</Typography>
                    <Button variant="outlined" component="span" onClick={() => setMediaImage(null)} >
                        Remove
                    </Button>
                </Stack>
            )
        }
        return (
            <Stack sx={{mt:2}} direction="row" alignItems="left" spacing={2}>
                <FormControl>
                    <label htmlFor="contained-button-file">
                        <Input  accept="image/*" id="contained-button-file" multiple type="file" onChange={onImageSelect}/>
                        <Button variant="outlined" component="span" >
                            Select Image
                        </Button>
                    </label>
                </FormControl>
            </Stack>
        )
    }

    const GetVideoMedia = () => {
        if(mediaVideo) {
            return (
                <Stack sx={{mt:2}} direction="row-reverse" alignItems="left" spacing={2}>
                    <Typography sx={{width: '100%',  mt: 1}} variant='body'>{mediaVideo?.name}</Typography>
                    <Button variant="outlined" component="span" onClick={() => setMediaVideo(null)} >
                        Remove
                    </Button>
                </Stack>
            )
        }
        return (
            <Stack sx={{mt:2}} direction="row" alignItems="left" spacing={2}>
                <FormControl>
                    <label htmlFor="contained-button-file">
                        <Input  accept="video/*" id="contained-button-file" multiple type="file" onChange={onVideoSelect}/>
                        <Button variant="outlined" component="span" >
                            Select Video
                        </Button>
                    </label>
                </FormControl>
            </Stack>
        )
    }

    const GetMediaTypes = () => {
        console.log('GetMediaTypes', mediaType);
        if (mediaType === 'V') {
            return (
                <Grid item xs={12}>
                    <GetVideoMedia />
                    {errors.video && <p>{errors.video.message}</p>}   
                </Grid>
            )
        }

        return (
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="Text"
                    multiline
                    rows={4}
                    autoFocus
                {...register("text", { required: "Enter announcement text" })}
                />
                {errors.text && <p>{errors.text.message}</p>}
                <GetImageMedia />     
                {errors.photo && <p>{errors.photo.message}</p>}           
            </Grid>
        )
    }    

    React.useEffect(() => {
        if(show) {
            
            setValue('time_start', getTimePlusMins(2))
            setValue('time_end', getTimePlusHours(3))
            setValue('date_start', getDatePlusMins(2))
            setValue('date_end', getDatePlusHours(3))
           
        }
    }, [show])


    return (
        <Dialog  disableBackdropClick  open={show} >
        <DialogTitle>Create Greeting</DialogTitle>
        <DialogContent>
            <form id="save-form" onSubmit={handleSubmit(saveData)}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Announcement Type</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={mediaType}
                                        onChange={handleChangeMediaType}
                                    >
                                        <FormControlLabel value="T" control={<Radio />} label="Text / Photo" />
                                        <FormControlLabel value="V" control={<Radio />} label="Video" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <GetMediaTypes />
                            <Grid item xs={12}>
                                <ErrorMessage
                                    errors={errors}
                                    name="singleErrorInput"
                                    render={({ message }) => <p>{message}</p>}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    </Box>
                </Container>
            </form>  
        </DialogContent>
        <DialogActions>
            <Button variant="outlined" onClick={() => handleClose()}>Close</Button>
            <Button variant="contained" type='submit'  form="save-form">Save</Button>
        </DialogActions>
    </Dialog>
    )
  }

export default AddGreetingsModal;

