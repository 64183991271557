import * as Yup from 'yup';
import React, { useState, useEffect } from 'react';
import {  Link as RouterLink, useNavigate } from 'react-router-dom';

import { useFormik, Form, FormikProvider } from 'formik';

import { useForm, Controller } from "react-hook-form";

// material
import { Link, Stack, Box, TextField, IconButton, InputAdornment, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import { showToast } from '../../../../utils/toast';
import { logout, saveUser } from "../../../../utils/session";
import { apiUrl, apiPostUnsecure } from "../../../../utils/api";

// ----------------------------------------------------------------------

export default function ActivateForm(token) {
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);

    const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
    } = useForm();

    const [error, setError] = useState("");

    async function activate(data) {

      console.log('activate', data.password, data.confrim_password)

      if(data.password !== data.confrim_password) {
        console.log('error', 'Password is not matching')
        showToast('warning',  'Password is not matching');
        return
      }

      data.token = token.token 

      setLoading(true);
      apiPostUnsecure(
        apiUrl.activate,
        data,
        (response) => {
          setLoading(false);
          showToast('success', 'Account activated! you can login now.');
          navigate("/login");
        },
        (errorMsg) => {
          setLoading(false);
          console.log('error', errorMsg)
          showToast('error', errorMsg);
        }
      );
    }

  return (
    <Box >
      <form id="hook-form" onSubmit={handleSubmit(activate)}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="password"
            label="Create Password"
            {...register("password", { required: "Enter password" })}
          />

        <TextField
            fullWidth
            autoComplete="password"
            label="Confirm Passowrd"
            {...register("confrim_password", { required: "Enter confirm password" })}
          />
        </Stack>
        <LoadingButton sx={{ my: 2 }} fullWidth size="large" type='submit'  form="hook-form" variant="contained" loading={isLoading} >
          Activate Account
        </LoadingButton>
      </form>
    </Box>
  );
}
