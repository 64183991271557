import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Slide from '@mui/material/Slide';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { LoadingButton } from '@mui/lab';
import callHelp from '../../../assets/call_help.png'

const CallDialogModel = (props) => {

    const { state, handleclose,  addclickhandler } = props;
  
    
    const handleCloseButton = (event, reason) => {
      if (reason && reason === "backdropClick") {
          return;
      }
      handleclose(false);
    }
  
    return (
      <>
        <Dialog
          open={state}
          fullWidth
          maxWidth="md"
          onClose={handleCloseButton}
          aria-labelledby="alertmodalCloseHandler-dialog-title"
          aria-describedby="alert-dialog-description"
          BackdropProps={{ style: { background: 'rgba(0, 0, 0, 0.5)' } }}
        >
          <div>
            <DialogTitle> 
                <Typography variant='h5' color='primary'>
                    Incoming call
                </Typography>
            </DialogTitle>
            <DialogContent>
              <Box sx={{ flexGrow: 1 }}>
                <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center">
                    <Typography variant='h6'>
                        Someone is trying to call using temi.
                    </Typography>
                    {/* <img src={callHelp} height='200px'/>
                    <Typography variant='subtitle'>
                        Please select allow <b>"Share system audio"</b> while screen sharing to record call session with audio.
                    </Typography> */}
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Box>
                <Button onClick={() => handleclose(false)} autoFocus variant="outlined" style={{ marginRight: 5 }}>
                    Reject
                </Button>
                <LoadingButton onClick={() => addclickhandler(state)} variant="contained">
                    Accept Call
                </LoadingButton>
              </Box>
            </DialogActions>
          </div>
        </Dialog>
      </>
    );
  };
  

export default CallDialogModel;