import { func } from "prop-types";

export function getAccessToken() {
    return sessionStorage.getItem('access', null);
}

export function isAuthenticated() {
    const access = sessionStorage.getItem('access', null);
    console.log(`access >> ${access}`);

    if (access != null) {
        return true
    }

    return false
}

export default isAuthenticated;

export function saveUser(result) {
    
    console.log('saveUser', result);

    sessionStorage.setItem('refresh', result.refresh)
    sessionStorage.setItem('access', result.access)
    sessionStorage.setItem('first_name', result.account.first_name)
    sessionStorage.setItem('last_name', result.account.last_name)
    sessionStorage.setItem('mobile', result.account.mobile)
    sessionStorage.setItem('email', result.account.email)
    sessionStorage.setItem('role', result.account.role)
    sessionStorage.setItem('username', result.account.username)
    sessionStorage.setItem('photo', result.account.photo)
    sessionStorage.setItem('is_active', result.account.is_active)
    sessionStorage.setItem('verified', result.account.verified)
    sessionStorage.setItem('department', result.account.department)
    sessionStorage.setItem('designation', result.account.designation)

    sessionStorage.setItem('firm_id', result.firm.id)
    sessionStorage.setItem('logo', result.firm.token_type)
    sessionStorage.setItem('company', result.firm.name)
    sessionStorage.setItem('address', result.firm.address)
    sessionStorage.setItem('loc_lat', result.firm.loc_lat)
    sessionStorage.setItem('loc_lon', result.firm.loc_lon)
    sessionStorage.setItem('host_id', result.firm.host_id)
    sessionStorage.setItem('temi_id', result.firm.temi_id)
}

export function updateUser(result) {
    sessionStorage.setItem('first_name', result.account.first_name)
    sessionStorage.setItem('last_name', result.account.last_name)
    sessionStorage.setItem('mobile', result.account.mobile)
    sessionStorage.setItem('email', result.account.email)
    sessionStorage.setItem('role', result.account.role)
    sessionStorage.setItem('is_active', result.account.is_active)
    sessionStorage.setItem('verified', result.account.verified)
    sessionStorage.setItem('department', result.account.department)
    sessionStorage.setItem('designation', result.account.designation)

    sessionStorage.setItem('firm_id', result.firm.id)
    sessionStorage.setItem('logo', result.firm.token_type)
    sessionStorage.setItem('company', result.firm.name)
    sessionStorage.setItem('address', result.firm.address)
    sessionStorage.setItem('loc_lat', result.firm.loc_lat)
    sessionStorage.setItem('loc_lon', result.firm.loc_lon)
    sessionStorage.setItem('host_id', result.firm.host_id)
    sessionStorage.setItem('temi_id', result.firm.temi_id)
}

export function logout() {
    sessionStorage.removeItem('refresh')
    sessionStorage.removeItem('access')
    sessionStorage.removeItem('first_name')
    sessionStorage.removeItem('last_name')
    sessionStorage.removeItem('mobile')
    sessionStorage.removeItem('email')
    sessionStorage.removeItem('role')

    sessionStorage.removeItem('firm_id')
    sessionStorage.removeItem('logo')
    sessionStorage.removeItem('scope')
    sessionStorage.removeItem('company')
    sessionStorage.removeItem('address')
    sessionStorage.removeItem('loc_lat')
    sessionStorage.removeItem('loc_lon')
    sessionStorage.removeItem('host_id')
    sessionStorage.removeItem('temi_id')

    localStorage.clear();

    console.log(`clear storage:`)
}

export function getUser() {
    return {
        access: sessionStorage.getItem('access', null),
        firstName: sessionStorage.getItem('first_name', null),
        lastName: sessionStorage.getItem('last_name', null),
        company: sessionStorage.getItem('company', null),
        email: sessionStorage.getItem('email', null),
        mobile: sessionStorage.getItem('mobile', null),
        role: sessionStorage.getItem('role', null),
        address: sessionStorage.getItem('address', null),
    }
}

export const hostId = () => {
    return sessionStorage.getItem('host_id', null)
}

export const temiId = () => {
    return sessionStorage.getItem('temi_id', null)
}


export function getUserEmail() {
    return sessionStorage.getItem('email', null)
}

export function getfirmId() {
    return sessionStorage.getItem('firm_id', null)
}

export const roleName = () => {
    return sessionStorage.getItem('role', null);
}

export function isAdmin() {
    return (sessionStorage.getItem('role', null) === 'A')
}

export function isStaff() {
    return (sessionStorage.getItem('role', null) === 'ST')
}

export function getUserName() {
    return `${sessionStorage.getItem('first_name', '')} ${sessionStorage.getItem('last_name', '')}`
}

export function getUserId() {
    return sessionStorage.getItem('username', '')
}

export function getPhoto() {
    return sessionStorage.getItem('photo', '')
}

const roleIdName = (role) => {
    switch(role) {
        case 'A':
            return 'Admin'
        case 'U':
            return 'Staff'
        default:
            return 'Analyst'
    }
}


export const roleId = () => {
    return sessionStorage.getItem('role', null);
}

