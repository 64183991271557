import React, { useState, useEffect, useRef } from 'react';

import MUIDataTable from 'mui-datatables';
import { Navigate, useNavigate, Link as RouterLink } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  IconButton,
  CardContent,
  Grid,
  Box
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import {isAuthenticated} from '../../../utils/session'
import LoadingModal from '../../../components/settings/LoadingModal';
import AddBusModal, {AddBirthModal} from './modals';


import DialogModal from '../../../components/settings/DialogModal';
import { showError, showSucess } from '../../../utils/toast';
import {apiUrl, apiCallSecureGet, apiPostSecure, apiCallSecureDelete} from '../../../utils/api'

// components
import Page from '../../../components/Page';

const BusCard = ({list, onAddBus, onDelete}) => {
  const data = []
  list.forEach(
    (item) => {
      data.push(
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant='h6' color="text.secondary" >
            {item.name}
          </Typography>
          <Button  size="small" variant="outlined" color="secondary" onClick={() => onDelete(item)}>
            <DeleteIcon color="secondary" />
          </Button>
        </Stack>
      )
    }
  );

  return (
    <Card sx={{ mt:2 }}>
      <CardContent>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography sx={{ fontSize: 18 }} color="text.primary" >
              Bus
        </Typography>
        <Button variant="outlined" startIcon={<AddIcon />} onClick={() => onAddBus()}>
          Add Bus
        </Button>
      </Stack>
        {data}
      </CardContent>
  </Card>
  )
}

const GetBusses = ({busses}) => {
  const data = []
  busses.forEach(
    (item) => {
      data.push(
        <Typography sx={{mr:1}} variant='body' color="text.secondary" >
          {item}
        </Typography>
      )
    })
  
    return <Stack direction='col'>
      {data}
    </Stack>
}

const BirthCard = ({list, onAddBirth, onDelete, onEdit}) => {
  const data = []
  list.forEach(
    (item) => {
      data.push(
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box>
            <Typography variant='h6' color="text.secondary" >
              {item.name}
            </Typography>
            <GetBusses busses={item.buses} />
          </Box>
          <Box>
            <Button sx={{mx:1}} size="small" variant="outlined" color="secondary" onClick={() => onEdit(item)}>
              <EditIcon color="secondary" />
            </Button>
            <Button  size="small" variant="outlined" color="secondary" onClick={() => onDelete(item)}>
              <DeleteIcon color="secondary" />
            </Button>
          </Box>
        </Stack>
      )
    }
  );

  return (
    <Card sx={{ mt:2 }}>
      <CardContent>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography sx={{ fontSize: 18 }} color="text.primary" >
          Berth
        </Typography>
        <Button  variant="outlined" startIcon={<AddIcon />} onClick={() => onAddBirth()}>
          Add Berth
        </Button>
      </Stack>
        {data}
      </CardContent>
  </Card>
  )
}


const SettingsPage = () => {

  const [loadBusses, setLoadBuses] = useState(true);
  const [loadBirth, setLoadBirth] = useState(true);
  const [busList, setBusList] = useState([])
  const [birthList, setBirthList] = useState([])
  const [loadingTitle, setLoadingTitle] = useState(null);
  const [showAddBusModal, setShowAddBusModal] = useState(false)
  const [showAddBirthModal, setShowAddBirthModal] = useState(false)
  const [initModal, setInitModal] = useState(true)
  const [deleteBirth, setDeleteBirth] = useState(null)
  const [deleteBus, setDeleteBus] = useState(null)

  const editBirthData = useRef(null)

  if(!isAuthenticated()){
    return <Navigate to='/login'  />
  }

  const onBusAddListener = (status, message) => {
    if(status === 1) {
      setLoadingTitle(null);
      showSucess(message);
      setLoadBuses(true)
    }else if(status === 2) {
      setLoadingTitle(null);
      showError(message)
    }else {
      setLoadingTitle(message);
    }
  }

  const onBirthAddListener = (status, message) => {
    if(status === 1) {
      setLoadingTitle(null);
      showSucess(message);
      setLoadBirth(true)
    }else if(status === 2) {
      setLoadingTitle(null);
      showError(message)
    }else {
      setLoadingTitle(message);
    }
  }

  const onShowDeleteBrith = (birth) => {
    setDeleteBirth(birth)
  }

  const onShowCancelDeleteBirth = () => {
    setDeleteBirth(null)
  }

  const onDeleteBirth = (birth) => {
    const id = birth.id;
    setDeleteBirth(null);
    apiCallSecureDelete(
      apiUrl.birth, id,
      (response) => {
          showSucess(response.msg);
          setLoadBirth(true);
      },
      (error) => {
        showError(error||'Error');
        console.log('loadBusses', error||'Error');
      }
    )
  }

  const onShowDeleteBus = (bus) => {
    setDeleteBus(bus)
  }

  const onShowCancelDeleteBus = () => {
    setDeleteBus(null);
  }

  const onDeleteBus = (bus) => {
    const id = bus.id;
    setDeleteBus(null);
    apiCallSecureDelete(
      apiUrl.bus, id,
      (response) => {
          showSucess(response.msg);
          setLoadBuses(true);
      },
      (error) => {
        showError(error||'Error');
        console.log('loadBusses', error||'Error');
      }
    )
  }

  const onEditBirth = (birth) => {
    editBirthData.current = birth
    console.log('onEditBirth', birth.name)
    onAddBirthClickListener()
    setInitModal(true);
  }


  useEffect(() => {
    if(!loadBusses) {
      return
    }

    setLoadBuses(false);
    apiCallSecureGet(
      apiUrl.bus,
      (response) => {
        console.log('loadBusses', response);
        setBusList(response.buses);
      },
      (error) => {
        showError(error||'Error');
        console.log('loadBusses', error||'Error');
      }
    )

  }, [loadBusses])

  useEffect(() => {
    if(!loadBirth) {
      return
    }

    setLoadBirth(false);
    apiCallSecureGet(
      apiUrl.birth,
      (response) => {
        console.log('loadBirth', response);
        setBirthList(response.births);
      },
      (error) => {
        showError(error||'Error');
        console.log('loadBirth', error||'Error');
      }
    )

  }, [loadBirth])

  const onAddBusClickListener = () => {
    setShowAddBusModal(true);
  }

  const onAddBirthClickListener = () => {
    setShowAddBirthModal(true);
  }

  return (
    <Page title="Account">
      <Container>
        <LoadingModal
            title={loadingTitle} />
        <AddBusModal show={showAddBusModal} change={setShowAddBusModal} onSaveChange={onBusAddListener} onError={showError} />
        <AddBirthModal 
          show={showAddBirthModal} 
          change={setShowAddBirthModal} 
          birth={editBirthData} 
          busList={busList} 
          onSaveChange={onBirthAddListener} 
          onError={showError}
          initModal={initModal}
          setInitModal={setInitModal} />
        <DialogModal
          state={deleteBirth}
          title='Delete Berth?'
          message={`Do you want to delete ${deleteBirth?.name} berth?`}
          handleclose={onShowCancelDeleteBirth}
          addclickhandler={onDeleteBirth}
          buttonlabel="Yes, Delete"
        />   
        <DialogModal
          state={deleteBus}
          title='Delete Bus?'
          message={`Do you want to delete ${deleteBus?.name} bus?`}
          handleclose={onShowCancelDeleteBus}
          addclickhandler={onDeleteBus}
          buttonlabel="Yes, Delete"
        />                
        <Typography sx={{m:1}} variant='h3'>Settings</Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <BusCard list={busList} onAddBus={onAddBusClickListener} onDelete={onShowDeleteBus}/>
          </Grid>
          <Grid item xs={6}>
            <BirthCard list={birthList} onAddBirth={onAddBirthClickListener} onDelete={onShowDeleteBrith} onEdit={onEditBirth} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default SettingsPage;
