import { useEffect, useState, useRef } from 'react';
import { Outlet, Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc, getDocs, setDoc, doc, onSnapshot } from "firebase/firestore";
import { getDatabase, ref as sRef, set } from "firebase/database";
import useSound from 'use-sound'

import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
// ----------------------------------------------------------------------
import ALERT_TONE from '../../assets/office_phone.mp3'
import {isAuthenticated, hostId, temiId} from '../../utils/session';
import CallDialogModel from './models'

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;
const MAX_TIME_DIFF = 5000;

const DATABASE_URL = 'videoCalls'

const firebaseConfig = {
  apiKey: "AIzaSyChlS1r6aURBE0u3V7DS6Q3CHY78S8LidU",
  authDomain: "miroboticproject.firebaseapp.com",
  databaseURL: "https://miroboticproject.firebaseio.com",
  projectId: "miroboticproject",
  storageBucket: "miroboticproject.appspot.com",
  messagingSenderId: "387151318489",
  appId: "1:387151318489:web:ce680b2b3088870ddc1dc8",
  measurementId: "G-G5RCBZNVQ2"
};


const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const app = initializeApp(firebaseConfig);

// ----------------------------------------------------------------------


export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [incoming, setIncoming] = useState(false);
  const [initSbs, setInitSbs]  = useState(true);
  const audioPlayer = useRef(new Audio(ALERT_TONE)); 
  const sbsHostId = useRef(hostId())
  const sbsTemiId = useRef(temiId())

  const [play, { stop }] = useSound(ALERT_TONE)

  const navigate = useNavigate();

  if(!isAuthenticated()){
    return <Navigate to='/login'  />
  }

  useEffect(() => {
    console.log('incoming', incoming);
    if(incoming) {
      play();
        // audioPlayer.current?.play()
    }else {
      stop();
        // audioPlayer.current?.pause()
    }
  }, [incoming])

  useEffect(() => {

    if(initSbs) {
      try {
        const db = getFirestore(app);
        const unsub = onSnapshot(doc(db, DATABASE_URL, sbsHostId.current), (doc) => {
          try{
            const time = new Date().getTime();
            const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
            console.log('source', source);
            const data = doc.data();
            const diff = time - data.time;
            console.log("Current time: ", time, diff);
  
            console.log("Current data: ", data);
            if(diff < MAX_TIME_DIFF) {
              if(data.status === 'connect') {
                console.warn("INCOMING CALL");
                setIncoming(true);
              }else {
                setIncoming(false);
              }
            }
          }catch(e) {
            console.error("Data error: ", e);
          }
        });
      } catch (e) {
        console.error("Connect error: ", e);
      }
      setInitSbs(false);
    }
  }, [initSbs])

  const onAcceptCall = () => {
    setIncoming(false);
    setTimeout(() => {
      navigate("/video/call");
    }, 1000)
  }

  const onCancelCall = () => {
    setIncoming(false);
    const db = getFirestore(app);
    const docRef = setDoc(doc(db, DATABASE_URL, sbsHostId.current), {
      incoming: sbsTemiId.current,
      isAvailable: false,
      connId: sbsTemiId.current,
      status: 'reject',
      time: new Date().getTime(),
      user: sbsHostId.current
    });
  }

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <CallDialogModel
          state={incoming}
          handleclose={onCancelCall}
          addclickhandler={onAcceptCall}
        /> 
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
