import * as React from 'react';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Edit from '@mui/icons-material/Edit';
import Info from '@mui/icons-material/Info';
import Delete from '@mui/icons-material/Delete';
import Visibility from '@mui/icons-material/Visibility';
import { Grid, Tooltip, IconButton, Stack, Avatar, Box } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import VideoIcon from '../../../../assets/video-player.png'
import {apiUrl} from '../../../../utils/api'

const getVideoName = (text) => {
    
    if (text == null) {
      return '';
    }

    let video = text;
    if(video) {
      video = text.substring(7)
    }
    return video;
}

const getText = (text) => {
  if(text) {
    if(text.length > 55) {
      return `${text.substring(0, 55)}...`
    }
    return text;
  }
  return '------'
}

const GetImage = ({data}) => {
    if(!data.photo) {
        return null;
    }

    return (
      <Box sx={{ backgroundImage: `url(${apiUrl.announceMedia}${data.photo})`, width: '100%', height: '60%', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat' }}>
         
      </Box>
  )
}

const GetText = ({data}) => {
    return (
        <Box>
            <Typography sx={{height: '60px'}}>{getText(data.text)}</Typography>
            <GetImage data={data} />
        </Box>
    )
}

const GetVideo = ({data, onVideoPlay}) => {
    return (
        <Box>
          <Typography sx={{height: '60px', width: '100%', wordWrap: "break-word"}}>{getVideoName(data.video)}</Typography>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            
            style={
              {backgroundImage: `url(${VideoIcon})`, 
              height: '150px', 
              width: '100%' , 
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}>
            <Avatar sx={{ bgcolor: '#ff0000' }}>
              <IconButton sx={{ color: '#fff' }}  variant="contained" size="small" onClick={() => onVideoPlay(data)}>
                <PlayArrowIcon />
              </IconButton>
            </Avatar>     
          </Grid>
        </Box>
    )
}

const GetContent = ({data, onVideoPlay}) => {
    
    if(data.video) {
        return <GetVideo data={data} onVideoPlay={onVideoPlay}/>
    }

    return <GetText data={data} />

}


export default function GreetingsCard({data, role, onInfo, onVideoPlay, onEdit, onDelete}) {

  const GetActions = () => {

    if(role.current === 'A' || role.current === 'ST') {
      return (
        <Stack 
            spacing={2}
          sx={{width:'100%'}}
          justifyContent="center"
          alignItems="center"
          direction="row"> 
          <Tooltip title="View" sx={{m: 2}}>
            <Avatar sx={{ bgcolor: '#20499B' }}>
              <IconButton sx={{ color: '#fff' }}  variant="contained" size="small" onClick={() => onInfo(data)}>
                <Visibility />
              </IconButton>
            </Avatar>        
          </Tooltip>
          <Tooltip title="Delete" sx={{m: 2}}>
            <Avatar sx={{ bgcolor: '#FF4646' }}>
              <IconButton sx={{ color: '#fff' }}  variant="contained" size="small" onClick={() => onDelete(data)}>
                <Delete />
              </IconButton>
            </Avatar>            
          </Tooltip>
        </Stack>
      )
    }
    return (
      <Stack 
      sx={{width:'100%'}}
      justifyContent="center"
      alignItems="center"
      direction="row"> 
      <Tooltip title="View" sx={{mx: 1}}>
          <Avatar sx={{ bgcolor: '#20499B' }}>
            <IconButton sx={{ color: '#fff' }}  variant="contained" size="small"  onClick={() => onInfo(data)}>
              <Visibility />
            </IconButton>
          </Avatar>        
        </Tooltip>
      </Stack>
    )
  }


  return (
    <Card  sx={{ boxShadow: 1}} spacing={2}>
      <CardContent sx={{height: '250px'}}>
        <GetContent data={data} onVideoPlay={onInfo} />
      </CardContent>
      <CardActions sx={{rightAlignItem: {marginLeft: "auto"}}}>
        <GetActions />
      </CardActions>
    </Card>
  );
}