import * as React from 'react';
import { useForm } from "react-hook-form";
import validator from 'validator'

import {
    Box,
    Grid,
    Card,
    Stack,
    Button,
    Container,
    Typography,
    ListItemIcon,
    MenuItem,
    FormControl,
    FormControlLabel,
    Select,
    Avatar,
    CssBaseline,
    TextField,
    Link,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    OutlinedInput,
    InputLabel,
    Checkbox,
    ListItemText      
  } from '@mui/material';
  
import { ErrorMessage } from '@hookform/error-message';
import { apiCallPostWithForm, apiPostSecure, apiUrl } from '../../../../utils/api';


const AddBusModal = (props) => {

    const { show, change, onSaveChange, onError } = props;

    const [addApiCalling, setAddApiCalling] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }
        reset({
            name: "",
          }
        );
        change(false);
    }

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        reset,
        formState: { errors },
      } = useForm();

    const saveData = (data) => {

        setTimeout(() => {
            clearErrors();
        }, 3000);

        console.log('data', data);

        if(addApiCalling) {
            return
        }

        setAddApiCalling(true)

        onSaveChange(0, "Adding bus...");

        apiPostSecure(apiUrl.bus, data,
            (response) => {
                setAddApiCalling(false)
                reset({
                    name: "",
                  }
                );
                onSaveChange(1, response.msg);
                change(false);
           },
           (errorMsg) => {
                setAddApiCalling(false)
                onSaveChange(2,  errorMsg||'Error');
                console.log('add-bus', errorMsg||'Error');
           }
          )

    } 

    return (
        <Dialog open={show} onClose={handleClose}>
        <DialogTitle>Add Bus</DialogTitle>
        <DialogContent>
            <form id="save-form" onSubmit={handleSubmit(saveData)}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Bus No."
                                    autoFocus
                                    {...register("name", { required: "Enter bus number / name" })}
                                    />
                                    {errors.name && <p>{errors.name.message}</p>}
                            </Grid>
                            <Grid item xs={12}>
                                <ErrorMessage
                                    errors={errors}
                                    name="singleErrorInput"
                                    render={({ message }) => <p>{message}</p>}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    </Box>
                </Container>
            </form>  
        </DialogContent>
        <DialogActions>
            <Button variant="outlined" onClick={() => handleClose()}>Close</Button>
            <Button variant="contained" type='submit'  form="save-form">Save</Button>
        </DialogActions>
    </Dialog>
    )
  }

export default AddBusModal;



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const AddBirthModal = (props) => {

    const { show, change, onSaveChange, busList, birth, onError, initModal, setInitModal } = props;

    const [addApiCalling, setAddApiCalling] = React.useState(false);
    const [selectedBuses, setSelectedBuses] = React.useState([]);

    console.log('AddBirthModal', birth.current)

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setSelectedBuses(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );

      console.log('selectedBuses', selectedBuses)
    };


    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }
        reset({
            id:null,
            name: "",
          }
        );
        setSelectedBuses([]);
        birth.current = null;
        change(false);
    }

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        clearErrors,
        reset,
        formState: { errors },
      } = useForm();

    React.useEffect(() => {

        if(!initModal) {
            return
        }
        setInitModal(false);
        const data = birth.current;
        if(data != null) {
            setValue('id', data.id);
            setValue('name', data.name);
            setSelectedBuses(data.buses);
        }

    }, [initModal])

    const saveData = (data) => {

        setTimeout(() => {
            clearErrors();
        }, 3000);

        console.log('data', data);

        if(addApiCalling) {
            return
        }

        if(selectedBuses.length === 0) {
            setError('name', {
                type: "server",
                message: 'Select buses to add in berth.',
            });
            return
        }

        setAddApiCalling(true)

        data.buses = selectedBuses

        onSaveChange(0, "Adding bus...");

        apiPostSecure(apiUrl.birth, data,
            (response) => {
                setAddApiCalling(false)
                onSaveChange(1, response.msg);
                handleClose();
           },
           (errorMsg) => {
                setAddApiCalling(false)
                onSaveChange(2,  errorMsg||'Error');
                console.log('add-bus', errorMsg||'Error');
           }
          )

    } 

    return (
        <Dialog open={show} onClose={handleClose}>
        <DialogTitle>Add berth</DialogTitle>
        <DialogContent>
            <form id="save-form" onSubmit={handleSubmit(saveData)}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Berth Name / No."
                                    autoFocus
                                    {...register("name", { required: "Enter Berth name / no. " })}
                                    />
                                    {errors.name && <p>{errors.name.message}</p>}
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="demo-multiple-checkbox-label">Select Buses</InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={selectedBuses}
                                        onChange={handleChange}
                                        input={<OutlinedInput label="Select Buses" />}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={MenuProps}
                                        >
                                        {busList?.map((item) => (
                                            <MenuItem key={item.id} value={item.name}>
                                            <Checkbox checked={selectedBuses.indexOf(item.name) > -1} />
                                            <ListItemText primary={item.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <ErrorMessage
                                    errors={errors}
                                    name="singleErrorInput"
                                    render={({ message }) => <p>{message}</p>}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    </Box>
                </Container>
            </form>  
        </DialogContent>
        <DialogActions>
            <Button variant="outlined" onClick={() => handleClose()}>Close</Button>
            <Button variant="contained" type='submit'  form="save-form">Save</Button>
        </DialogActions>
    </Dialog>
    )
  }
