import * as React from 'react';

import styled from "styled-components";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Edit from '@mui/icons-material/Edit';
import Info from '@mui/icons-material/Info';
import Delete from '@mui/icons-material/Delete';
import { positions } from '@mui/system';

import Visibility from '@mui/icons-material/Visibility';
import { Grid, Tooltip, IconButton, Stack, Avatar, Box } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VideoIcon from '../../../../assets/video-player.png'
import {apiUrl} from '../../../../utils/api'
import {isBeforeToday} from '../../../../utils/ext'

const GetExpiryStatus = ({from, to}) => {

    if(!from || !to) {
      return null
    }

    if(!isBeforeToday(from)) {
      return <Typography sx={{m: 1}} variant='subtitle' color='primary'>Upcoming</Typography>
    }

    if(!isBeforeToday(to)) {
      return <Typography sx={{m: 1}} variant='subtitle' color='#0a0'>Active</Typography>
    }

    return <Typography sx={{m: 1}} variant='subtitle' color='error'>Expired</Typography>
}

const getVideoName = (text) => {
    
  if (text == null) {
    return '';
  }

  let video = text;
  if(video) {
    video = getTextTrim(text.substring(7), 15)
  }
  return video;
}

const getTextTrim = (text, max) => {
  if(text) {
    if(text.length > max) {
      return `${text.substring(0, max)}...`
    }
    return text;
  }
  return '------'
}


const getText = (text) => {
  if(text) {
    if(text.length > 55) {
      return `${text.substring(0, 50)}...`
    }
    return text;
  }
  return '------'
}

const GetImage = ({data}) => {
    if(!data.photo) {
        return null;
    }

    return (
        <Box sx={{ backgroundImage: `url(${apiUrl.announceMedia}${data.photo})`, width: '100%', height: '150px', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat' }}>
           
        </Box>
    )
}

const GetText = ({data}) => {
    return (
        <Box>
            <Typography variant='h6'>{getText(data.title)}</Typography>
            <Typography sx={{height: '60px', width: '90%',}}>{getText(data.text)}</Typography>
            <GetImage data={data} />
        </Box>
    )
}

const GetVideo = ({data, onVideoPlay}) => {
    return (
      <Box>
          <Typography variant='h6'>{getText(data.title)}</Typography>
          <Typography sx={{height: '60px', width: '90%', wordWrap: "break-word", mr: 2}} color='#888'>{getVideoName(data.video)}</Typography>
          <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              
              style={
                  {backgroundImage: `url(${VideoIcon})`, 
                  height: '150px', 
                  width: '100%' , 
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
              }}>
              <Avatar sx={{ bgcolor: '#ff0000' }}>
                <IconButton sx={{ color: '#fff' }}  variant="contained" size="small" onClick={() => onVideoPlay(data)}>
                  <PlayArrowIcon />
                </IconButton>
              </Avatar>  
          </Grid>
      </Box>
    )
}

const GetContent = ({data, onVideoPlay}) => {
    
    if(data.video) {
        return <GetVideo data={data} onVideoPlay={onVideoPlay}/>
    }

    return <GetText data={data} />

}

const GetScheduleIcon = ({data}) => {

  if(data.schedule) {
    return <AccessTimeIcon color="success" sx={{}}/>
  }
  return null;
}


export default function AnnouncementCard({data, role, onInfo, onVideoPlay, onEdit, onDelete}) {

  const GetActions = () => {

    if(role.current === 'A' || role.current === 'ST') {
      return (
        <Stack 
            spacing={2}
          sx={{width:'100%'}}
          justifyContent="center"
          alignItems="center"
          direction="row"> 
          <GetExpiryStatus from={data.time_start} to={data.time_end} />
          <Tooltip title="View" sx={{m: 2}}>
            <Avatar sx={{ bgcolor: '#20499B' }}>
              <IconButton sx={{ color: '#fff' }}  variant="contained" size="small" onClick={() => onInfo(data)}>
                <Visibility />
              </IconButton>
            </Avatar>        
          </Tooltip>
          <Tooltip title="Delete" sx={{m: 2}}>
            <Avatar sx={{ bgcolor: '#FF4646' }}>
              <IconButton sx={{ color: '#fff' }}  variant="contained" size="small" onClick={() => onDelete(data)}>
                <Delete />
              </IconButton>
            </Avatar>            
          </Tooltip>
        </Stack>
      )
    }
    return (
      <Stack 
      sx={{width:'100%'}}
      justifyContent="center"
      alignItems="center"
      direction="row"> 
      <Tooltip title="View" sx={{mx: 1}}>
          <Avatar sx={{ bgcolor: '#20499B' }}>
            <IconButton sx={{ color: '#fff' }}  variant="contained" size="small"  onClick={() => onInfo(data)}>
              <Visibility />
            </IconButton>
          </Avatar>        
        </Tooltip>
      </Stack>
    )
  }



  return (
    <Card  sx={{ boxShadow: 1}} spacing={2}>
      <CardContent sx={{height: '310px'}}>
        <Box sx={{position: 'relative'}}>
          <Box >
            <GetContent data={data} onVideoPlay={onInfo} />
          </Box>
          <Box sx={{position: 'absolute', top: 0, right: 0}}>
              <GetScheduleIcon data={data} />
          </Box>          
        </Box>
      </CardContent>
      <CardActions sx={{rightAlignItem: {marginLeft: "auto"}}}>
        <GetActions />
      </CardActions>
    </Card>
  );
}