import { useEffect, useState, React } from "react";
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Box, Link, Container, Typography, Stack } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import {LogoHome} from '../../components/Logo';
// sections
import ActivateForm from './forms/activate';
import {useQueryParams} from '../../utils/ext';
import {apiUrl, apiCallUnsecureGet, apiPostSecure, apiPostUnsecure} from '../../utils/api';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Activate() {

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  const { token } = useQueryParams();
  const [loadingTitle, setLoadingTitle] = useState('Laoding');
  const [user, setUser] = useState(null)
  const [verifyToken, setVerifyToken] = useState(true);

  console.log('token', token);

  const getForm = (user) => {

    if(loadingTitle) {
      return (null)
    }

    if(user) {
      return (
        <Box>
              <Typography variant="h4" gutterBottom>
                Welcome {user.first_name} {user.last_name}
              </Typography>
              <Typography variant="h6" gutterBottom>
                Create new password
              </Typography>
              <Typography sx={{ color: 'text.secondary', mb: 5 }}>Enter your details below.</Typography>
              <ActivateForm token={token} />
        </Box>
      )
    }

    return (
      <Box>
            <Typography variant="h4" gutterBottom>
              Invalid Link
            </Typography>
      </Box>
    )
  }

  const verifyTokenFromServer = async () => {
    if(token == null) {
      setLoadingTitle(null);
      return
    }
    if(!verifyToken) {
      return
    }
    
    setVerifyToken(false);

    setLoadingTitle('Please wait!');
    apiPostUnsecure(apiUrl.verifyActivateToken, {'token': token},
      (response) => {
          setLoadingTitle(null)
          setUser(response.user);
      },
      (errorMsg) => {
        setVerifyToken(false);
        setLoadingTitle(null)
        }
    )
}

useEffect(() => {
  verifyTokenFromServer();
}, [verifyToken])

  return (
    <Page title="Login">
    <RootStyle>
      <HeaderStyle>
        
        <Stack  alignItems="center" sx={{width: '100%', maxWidth: 300}}>
            <LogoHome />
        </Stack>

        {smUp && (
          <Typography variant="body2" sx={{ mt: { md: -2 } }}>
            Don’t have an account? {''}
            <Link variant="subtitle2" component={RouterLink} to="/register">
              Get started
            </Link>
          </Typography>
        )}
      </HeaderStyle>

      {mdUp && (
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      )}

      <Container maxWidth="sm">
        <ContentStyle>

          <Typography sx={{ color: 'text.secondary', mb: 5 }}>Enter your details to activate your account.</Typography>

          {getForm(user)}

          {!smUp && (
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?{' '}
              <Link variant="subtitle2" component={RouterLink} to="/register">
                Get started
              </Link>
            </Typography>
          )}
        </ContentStyle>
      </Container>
    </RootStyle>
  </Page>
  )
}
