import * as React from 'react';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Edit from '@mui/icons-material/Edit';
import Info from '@mui/icons-material/Info';
import Delete from '@mui/icons-material/Delete';
import Download from '@mui/icons-material/Download';
import Visibility from '@mui/icons-material/Visibility';
import { Grid, Tooltip, IconButton, Stack, Avatar, Box } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import VideoIcon from '../../../../assets/video-player.png'
import {apiUrl} from '../../../../utils/api'
import {fileToDate} from '../../../../utils/ext'

const getText = (text) => {
  if(text) {
    if(text.length > 55) {
      return `${text.substring(0, 55)}...`
    }
    return text;
  }
  return '------'
}


const GetContent = ({data}) => {
    return (
      <Box>
          <Typography variant='h6'>{fileToDate(data.name)}</Typography>
          <Typography variant='subtitle'>{data.name}</Typography>
      </Box>
    )
}


export default function CallCard({data, role, onDownload, onDelete}) {

  const GetActions = () => {

    if(role.current === 'A' || role.current === 'ST') {
      return (
        <Stack 
            spacing={2}
          sx={{width:'100%'}}
          justifyContent="center"
          alignItems="center"
          direction="row"> 
          <Tooltip title="Download" sx={{m: 2}}>
            <Avatar sx={{ bgcolor: '#00dd00' }}>
              <IconButton sx={{ color: '#fff' }}  variant="contained" size="small" onClick={() => onDownload(data)}>
                <Download />
              </IconButton>
            </Avatar>            
          </Tooltip>
          <Tooltip title="Delete" sx={{m: 2}}>
            <Avatar sx={{ bgcolor: '#FF4646' }}>
              <IconButton sx={{ color: '#fff' }}  variant="contained" size="small" onClick={() => onDelete(data)}>
                <Delete />
              </IconButton>
            </Avatar>            
          </Tooltip>
        </Stack>
      )
    }
    return null
  }


  return (
    <Card  sx={{ boxShadow: 1}} spacing={2}>
      <CardContent sx={{height: '100px'}}>
        <GetContent data={data} />
      </CardContent>
      <CardActions sx={{rightAlignItem: {marginLeft: "auto"}}}>
        <GetActions />
      </CardActions>
    </Card>
  );
}