import React, { useState, useEffect, useRef } from 'react';

import MUIDataTable from 'mui-datatables';
import { Navigate, useNavigate, Link as RouterLink } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  IconButton,
  CardContent,
  Grid,
  Box
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import {isAuthenticated, roleId} from '../../../utils/session'
import LoadingModal from '../../../components/settings/LoadingModal';
import AddGreetingsModal, {GreetingInfoModal} from './modals'
import GreetingsCard from './cards'

import DialogModal from '../../../components/settings/DialogModal';
import { showError, showSucess } from '../../../utils/toast';
import {apiUrl, apiCallSecureGet, apiPostSecure, apiCallSecureDelete} from '../../../utils/api'

// components
import Page from '../../../components/Page';

const GreetingsPage = () => {

  const [loadData, setLoadData] = useState(true);
  const [rows, setRows] = useState([]);

  const [deleteData, setDeleteData] = useState(null)

  const editData = useRef(null)
  const infoData = useRef(null)

  const [showDataAdd, setShowDataAdd] = useState(false)
  const [showDataInfo, setShowDataInfo] = useState(false)

  const [loadingTitle, setLoadingTitle] = useState(null);

  const roleIdName = useRef(roleId());

  const onCancelDelete = () => {
    setDeleteData(null);
  }

  const onDeleteShow = (data) => {
    console.log('onDeleteShow', data);
    setDeleteData(data)
  }

  const onDelete = (data) => {
    const id = data.id;
    setDeleteData(null);
    apiCallSecureDelete(
      apiUrl.greeting, id,
      (response) => {
          showSucess(response.msg);
          setLoadData(true);
      },
      (error) => {
        showError(error||'Error');
        console.log('loadBusses', error||'Error');
      }
    )
  }
  
  const onEdit = (data) => {
    editData.current = data
  }

  const onInfo = (data) => {
    infoData.current = data
    setShowDataInfo(true)
  }

  const onPlayVideo = (data) => {

  }

  useEffect(() => {

    if(!loadData) {
      return
    }
    setLoadData(false)

    apiCallSecureGet(apiUrl.greeting, 
      (response) => {
        console.log(response);
      },
      (error) => {
        showError(error||'Error');
        console.log('loadBirth', error||'Error');
      })


  }, [loadData])

  const onAddGreetingListener = (status, message) => {
    if(status === 1) {
      setLoadingTitle(null);
      showSucess(message);
      setLoadData(true)
    }else if(status === 2) {
      setLoadingTitle(null);
      showError(message)
    }else {
      setLoadingTitle(message);
    }
  }

  const GetHeader = () => {
      return(
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant='h6' color="text.secondary" >
            Greetings
          </Typography>
          <Button variant="outlined" startIcon={<AddIcon />} onClick={() => setShowDataAdd(true)}>
            Add 
          </Button>
        </Stack>
      )
  }

  useEffect(() => {
    
    if(!loadData) {
      return
    }
    
    setLoadData(false);
    apiCallSecureGet(apiUrl.greeting, 
        (response) => {
            const itemList = response.list.map((item) => (
                <Grid item xs={12} md={6} lg={3}>
                    <GreetingsCard data={item} role={roleIdName} onEdit={onEdit}  onDelete={onDeleteShow} onVideoPlay={onPlayVideo} onInfo={onInfo} />
                </Grid>
            ));
            setRows(itemList)
        },
        (error) => {
            console.log('error', error);
            showError('Error!', error||'Something went wrong.');
        });
    
    
}, [loadData])

  return (
      <Page title="Greetings">
      <Container>
        <LoadingModal
            title={loadingTitle} />
        <DialogModal
          state={deleteData}
          title='Delete Greetings?'
          message={`Do you want to delete this greeting?`}
          handleclose={onCancelDelete}
          addclickhandler={onDelete}
          buttonlabel="Yes, Delete"
        />   
        <AddGreetingsModal
          show={showDataAdd} change={setShowDataAdd} state={editData} onSaveChange={onAddGreetingListener} onError={showError} />
        <GreetingInfoModal
            show={showDataInfo} change={setShowDataInfo} state={infoData} />
        <Card sx={{p:2}} >
          <GetHeader />
        </Card>
        <Grid container spacing={2} sx={{mt: 2}}>
          {rows}          
        </Grid>
      </Container>
    </Page>
    );
}

export default GreetingsPage;
