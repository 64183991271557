import * as React from 'react';
import { useForm } from "react-hook-form";
import validator from 'validator'

import {
    styled,
    Box,
    Grid,
    Card,
    Stack,
    Button,
    Container,
    Typography,
    IconButton,
    MenuItem,
    FormControl,
    FormControlLabel,
    Select,
    Avatar,
    CssBaseline,
    TextField,
    Link,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    FormLabel,
    InputLabel,
    RadioGroup,
    Radio,      
  } from '@mui/material';
  import { AudioCard, VideoCard } from 'material-ui-player'

import dayjs from 'dayjs';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ReactPlayer from 'react-player'

import { ErrorMessage } from '@hookform/error-message';
import { apiCallPostWithForm, apiPostSecure, apiUrl } from '../../../../utils/api';
import { checkIsBefore, getTimePlusMins, getTimePlusHours, getDatePlusMins, getDatePlusHours,  dateToShowDateFormat, dateToServerFormat, dateFromFromServer, timeFromFromServer, isBeforeToday} from '../../../../utils/ext'

const Input = styled('input')({
    display: 'none',
  });

  const GetExpiryStatus = ({from, to}) => {

    if(!from || !to) {
      return null
    }

    if(!isBeforeToday(from)) {
      return <Typography sx={{mt: 1}} variant='subtitle' color='primary'>Upcoming</Typography>
    }

    if(!isBeforeToday(to)) {
      return <Typography sx={{mt: 1}} variant='subtitle' color='#0a0'>Active</Typography>
    }

    return <Typography sx={{mt: 1}} variant='subtitle' color='error'>Expired</Typography>
}

  const GetSchedule = ({data}) => {
        console.log('GetSchedule', data.schedule);
        if(data.schedule) {
            console.log('GetSchedule', data.time_start,  data.time_end);
            return (
                <Stack sx={{width: '100%'}}>
                    <Box sx={{width: '100%', mt:2}}>
                        <Typography variant='h6'>Shcedule:</Typography>
                        <Typography variant='subtitle1'>{dateToShowDateFormat(data.time_start)}</Typography>
                        <Typography variant='subtitle1'>to</Typography>
                        <Typography variant='subtitle1'>{dateToShowDateFormat(data.time_end)}</Typography>
                    </Box>
                    <GetExpiryStatus from={data.time_start} to={data.time_end} />
                </Stack>
            )
        }
        return null;
    }
  const GetImage = ({data}) => {
      if(!data.photo) {
          return null;
      }
  
      return (
          <Box>
              <img src={`${apiUrl.announceMedia}${data.photo}`} height='360px'/>
          </Box>
      )
  }
  
  const GetText = ({data}) => {
      return (
            <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
        >
                <GetImage data={data} />
                <Typography variant='h6' >{data.title}</Typography>
                <Typography>{data.text}</Typography>
                <GetSchedule data={data} />
            </Grid>
      )
  }
  
  const GetVideo = ({data}) => {
      return (
          <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
                <video width="640px" height="360px" controls>
                    <source src={`${apiUrl.announceMedia}${data.video}`}  type="video/mp4" />                    
                    <track default/>
                </video>
                <Typography variant='h6' >{data.title}</Typography>
                {/* <ReactPlayer src={`${apiUrl.announceMedia}${data.video}`} width="640px" height="360px" /> */}
                <GetSchedule data={data} />
          </Grid>
      )
  }
  
  const GetContent = ({state}) => {
      const data = state.current
      if(data == null) {
        return
      }

      if(data.video) {
          return <GetVideo data={data}/>
      }
  
      return <GetText data={data} />
  
  }
  



export const AnnouncementInfoModal = (props) => {

    const { show, change, state, onSuccess,  onError } = props;
    const [scroll, setScroll] = React.useState('paper');

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }
        state.current = null;
        change(false);
    }

    const notifyAnnouncement = () => {
        const aId = state.current?.id;
        if(aId == null) {
            return
        }
        const data = {
            announcement: aId
        }

        apiPostSecure(apiUrl.notifyAnnounce, data,
            (response) => {
                onSuccess(response.msg);
                change(false);
            },
            (error) => {
                onError(error||'Error');
            }
        )

    }

    const GetResendButton = ({state}) => {
        const data = state.current
        if(data == null) {
          return null;
        }

        if(data.schedule) {
            return null;
        }

        return <Button variant="outlined" onClick={() => notifyAnnouncement()}>Send to temi</Button>
    }

    return (
        <Dialog 
            open={show} 
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            fullWidth='true'
            maxWidth='md'
            >
        <DialogTitle id="scroll-dialog-title">Announcement</DialogTitle>
        <DialogContent>
            <GetContent state={state} />
        </DialogContent>
        <DialogActions>
            <GetResendButton state={state} />
            <Button variant="outlined" onClick={() => change(false)}>Close</Button>
        </DialogActions>
    </Dialog>
    )
  }  

const AddAnnoucementModal = (props) => {

    const { show, change, state, onSaveChange, dates, onError } = props;

    const [addApiCalling, setAddApiCalling] = React.useState(false);
    const [repeatMode, setRepeatMode] = React.useState('N');
    const [mediaType, setMediaType] = React.useState('T');

    const [mediaImage, setMediaImage] = React.useState(null);
    const [mediaVideo, setMediaVideo] = React.useState(null);

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }
        reset({
            title: "",
            text: "",
            time_start: null,
            time_end: null,
          }
        );
        setMediaType('T');
        setRepeatMode('N');
        setMediaImage(null);
        setMediaVideo(null);
        state.current = null;
        change(false);
    }

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        reset,
        setValue,
        formState: { errors },
      } = useForm();
    
    const showFormError = (name, error) => {
        setError(name, {
            type: "server",
            message: error
        });
        setTimeout(() => {
            clearErrors();
        }, 3000);
    }  

    const saveData = (data) => {

        setTimeout(() => {
            clearErrors();
        }, 3000);

        console.log('data', data);

        if(addApiCalling) {
            return
        }

        const formData = new FormData();
        formData.append("title", data.title);

        if(mediaType === 'T') {
            if(data.text.length < 10) {
                showFormError('text', 'Please enter at least 10 characters')      
                return        
            }

            formData.append("text", data.text);
            if(mediaImage !== null) {
                formData.append("photo", mediaImage);
            }
            console.log('photo', mediaImage);
        }else {
            if(mediaVideo === null) {
                showFormError('video', 'Please select video file')      
                return    
            }
            console.log('video', mediaVideo);
            formData.append("video", mediaVideo);
        }
        
        if(repeatMode === 'R') {
            if(data.time_start === null) {
                showFormError('video', 'Please select start date')
                return      
            }
            if(data.time_start === null) {
                showFormError('video', 'Please select start time')
                return      
            }

            if(data.time_end === null) {
                showFormError('video', 'Please select end date')
                return      
            }
            if(data.time_end === null) {
                showFormError('video', 'Please select end time')
                return      
            }
            
            const timeStart = dateToServerFormat(data.date_start, data.time_start);
            const timeEnd = dateToServerFormat(data.date_end, data.time_end);
            
            const isValidDates = checkIsBefore(timeStart, timeEnd)
            console.log('isValidDates', isValidDates)
            if(!isValidDates) {
                showFormError('time_end', 'End time is before start time')
                return
            }
            formData.append("schedule", 'Y');
            formData.append("time_start", timeStart);
            formData.append("time_end", timeEnd);
        }else {
            formData.append("schedule", 'N');
        }
        if(data.id) {
          formData.append("id", state.current?.id);
        }

        setAddApiCalling(true)

        onSaveChange(0, "Saving announcement...");

        apiCallPostWithForm(apiUrl.announcement, formData,
            (response) => {
                setAddApiCalling(false)
                onSaveChange(1, response.msg);
                handleClose()
           },
           (errorMsg) => {
                setAddApiCalling(false)
                onSaveChange(2,  errorMsg||'Error');
                console.log('add-bus', errorMsg||'Error');
           }
          )
    } 

    const handleChangeRepeatMode = (event) => {
        setRepeatMode(event.target.value);
      };

    const handleChangeMediaType = (event) => {
        setMediaType(event.target.value);
        console.log('media', mediaType)
    };   


    
    const onImageSelect = (value) => {
        console.log('logo', value);
        const file = value.target.files[0];
        if (file && !file.name) {
            showFormError('photo', 'Please select image file.');
           return false;
        }
        console.log('logo size', file.size);

        if (file.size > 1e6) {
            showFormError('photo', 'Please upload a image smaller than 1 MB');
            return false;
        }
        setMediaImage(file)
    }

    const onVideoSelect = (value) => {
        console.log('logo', value);
        const file = value.target.files[0];
        if (file && !file.name) {
            showFormError('video', 'Please select video file.');
           return false;
        }
        console.log('logo size', file.size);

        if (file.size > 20e6) {
            showFormError('video', 'Please upload a video smaller than 20 MB');
            return false;
        }
        setMediaVideo(file)
    }

    const GetImageMedia = () => {
        if(mediaImage) {
            return (
                <Stack sx={{mt:2}} direction="row-reverse" alignItems="left" spacing={2}>
                    <Typography sx={{width: '100%',  mt: 1}} variant='body'>{mediaImage?.name}</Typography>
                    <Button variant="outlined" component="span" onClick={() => setMediaImage(null)} >
                        Remove
                    </Button>
                </Stack>
            )
        }
        return (
            <Stack sx={{mt:2}} direction="row" alignItems="left" spacing={2}>
                <FormControl>
                    <label htmlFor="contained-button-file">
                        <Input  accept="image/*" id="contained-button-file" multiple type="file" onChange={onImageSelect}/>
                        <Button variant="outlined" component="span" >
                            Select Image
                        </Button>
                    </label>
                </FormControl>
            </Stack>
        )
    }

    const GetVideoMedia = () => {
        if(mediaVideo) {
            return (
                <Stack sx={{mt:2}} direction="row-reverse" alignItems="left" spacing={2}>
                    <Typography sx={{width: '100%',  mt: 1}} variant='body'>{mediaVideo?.name}</Typography>
                    <Button variant="outlined" component="span" onClick={() => setMediaVideo(null)} >
                        Remove
                    </Button>
                </Stack>
            )
        }
        return (
            <Stack sx={{mt:2}} direction="row" alignItems="left" spacing={2}>
                <FormControl>
                    <label htmlFor="contained-button-file">
                        <Input  accept="video/*" id="contained-button-file" multiple type="file" onChange={onVideoSelect}/>
                        <Button variant="outlined" component="span" >
                            Select Video
                        </Button>
                    </label>
                </FormControl>
            </Stack>
        )
    }

    const GetMediaTypes = () => {
        console.log('GetMediaTypes', mediaType);
        if (mediaType === 'V') {
            return (
                <Grid item xs={12}>
                    <GetVideoMedia />
                    {errors.video && <p>{errors.video.message}</p>}   
                </Grid>
            )
        }

        return (
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="Text"
                    multiline
                    rows={4}
                {...register("text", { required: "Enter announcement text" })}
                />
                {errors.text && <p>{errors.text.message}</p>}
                <GetImageMedia />     
                {errors.photo && <p>{errors.photo.message}</p>}           
            </Grid>
        )
    }    


    const GetRepeatDateStart = () => {
        if (repeatMode === 'N') {
            return null
        }

        return (
            <Grid item xs={6}>
                <Stack spacing={3}>
                    <Typography variant='body'>Start date and time</Typography>
                    <TextField type="date" placeholder="Date" {...register("date_start")} /> 
                    {errors.date_start && <p>{errors.date_start.message}</p>}        
                </Stack>
            </Grid>
        )
    }

    
    const GetRepeatTimeStart = () => {
        if (repeatMode === 'N') {
            return null
        }

        return (
            <Grid item xs={6}>
                <Stack spacing={3}>
                    <Typography variant='body'>&nbsp;</Typography>
                    <TextField type="time" placeholder="Time"  {...register("time_start")} /> 
                    {errors.time_start && <p>{errors.time_start.message}</p>}     
                </Stack>
            </Grid>
        )
    }

    
    const GetRepeatDateEnd = () => {
        if (repeatMode === 'N') {
            return null
        }

        return (
            <Grid item xs={6}>
                <Stack spacing={3}>
                    <Typography variant='body'>End date and time</Typography>
                    <TextField type="date" placeholder="Date" {...register("date_end")} /> 
                    {errors.date_end && <p>{errors.date_end.message}</p>}        
                </Stack>
            </Grid>
        )
    }
      
    const GetRepeatTimeEnd = () => {
        if (repeatMode === 'N') {
            return null
        }

        return (
            <Grid item xs={6}>
                <Stack spacing={3}>
                    <Typography variant='body'>&nbsp;</Typography>
                    <TextField type="time" placeholder="Time"  {...register("time_end")} /> 
                    {errors.time_end && <p>{errors.time_end.message}</p>}     
                </Stack>
            </Grid>
        )
    }

    React.useEffect(() => {
        if(show) {
            
            setValue('time_start', getTimePlusMins(2))
            setValue('time_end', getTimePlusHours(3))
            setValue('date_start', getDatePlusMins(2))
            setValue('date_end', getDatePlusHours(3))
           
        }
    }, [show])


    return (
        <Dialog open={show} onClose={handleClose}>
        <DialogTitle>Create Announcement</DialogTitle>
        <DialogContent>
            <form id="save-form" onSubmit={handleSubmit(saveData)}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Announcement Type</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={mediaType}
                                        onChange={handleChangeMediaType}
                                    >
                                        <FormControlLabel value="T" control={<Radio />} label="Text / Photo" />
                                        <FormControlLabel value="V" control={<Radio />} label="Video" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Title"
                                    autoFocus
                                {...register("title", { required: "Enter announcement title" })}
                                />
                                {errors.title && <p>{errors.title.message}</p>}
                            </Grid>
                            <GetMediaTypes />
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Repeat</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={repeatMode}
                                        label="repeat"
                                        onChange={handleChangeRepeatMode}
                                    >
                                        <MenuItem value='N'>No repeat</MenuItem>
                                        <MenuItem value='R'>Repeat</MenuItem>
                                    </Select>
                                    </FormControl>
                            </Grid>
                            <GetRepeatDateStart />
                            <GetRepeatTimeStart />
                            <GetRepeatDateEnd />
                            <GetRepeatTimeEnd />
                            <Grid item xs={12}>
                                <ErrorMessage
                                    errors={errors}
                                    name="singleErrorInput"
                                    render={({ message }) => <p>{message}</p>}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    </Box>
                </Container>
            </form>  
        </DialogContent>
        <DialogActions>
            <Button variant="outlined" onClick={() => handleClose()}>Close</Button>
            <Button variant="contained" type='submit'  form="save-form">Save</Button>
        </DialogActions>
    </Dialog>
    )
  }

export default AddAnnoucementModal;

