import * as React from 'react';
import { useForm } from "react-hook-form";
import validator from 'validator'

import {
    Box,
    Grid,
    Card,
    Stack,
    Button,
    Container,
    Typography,
    ListItemIcon,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Avatar,
    CssBaseline,
    TextField,
    Link,
    Checkbox,
    FormControlLabel,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
  } from '@mui/material';
  
import { ErrorMessage } from '@hookform/error-message';
import { apiCallPostWithForm, apiCallPutWithForm, apiUrl } from '../../../../utils/api';


const ChangePasswordModal = (props) => {

    const { show, change, onSaveChange, onError } = props;

    const [addApiCalling, setAddApiCalling] = React.useState(false);

    const handleClose = () => {
        reset({
            current: "",
            new: "",
            repeat: "",
          }
        );
        change(false);
    }

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        reset,
        formState: { errors },
      } = useForm();

    const saveData = (data) => {

        setTimeout(() => {
            clearErrors();
        }, 3000);

        console.log('data', data);

        if(addApiCalling) {
            return
        }

        if (!validator.isStrongPassword(data.new, {minLength: 6, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1})){
            setError('new', {
              type: "server",
              message: 'Password is not strong.',
            });
            console.log('new', errors.new);
            return
        }

        if (data.new !== data.repeat){
          setError('new', {
            type: "server",
            message: 'Password is not matching.',
          });
          setError('repeat', {
            type: "server",
            message: 'Password is not matching.',
          });
          return
      }

      if (data.current === data.new){
        setError('new', {
          type: "server",
          message: 'New password is same as current.',
        });
        return
    }

        setAddApiCalling(true)

        const formData = new FormData();
        formData.append('current', data.current);
        formData.append('new', data.new);
        
        onSaveChange(0, "Updating password...");

        apiCallPostWithForm(apiUrl.changePassword, formData,
            (response) => {
                setAddApiCalling(false)
                reset({
                    current: "",
                    new: "",
                    repeat: "",
                  }
                );
                onSaveChange(1, response.msg);
                change(false);
           },
           (errorMsg) => {
                setAddApiCalling(false)
                onSaveChange(2,  errorMsg||'Error');
                console.log('change-password', errorMsg||'Error');
           }
          )

    } 

    return (
        <Dialog open={show} onClose={handleClose}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
            <form id="save-form" onSubmit={handleSubmit(saveData)}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Current password"
                                    autoFocus
                                    {...register("current", { required: "Enter current password" })}
                                    />
                                    {errors.current && <p>{errors.current.message}</p>}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="New password"
                                    {...register("new", { required: "Enter new password" })}
                                    />
                                    {errors.new && <p>{errors.new.message}</p>}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Confirm new password"
                                    {...register("repeat", { required: "Enter passwor to confirm" })}
                                    />
                                    {errors.repeat && <p>{errors.repeat.message}</p>}
                            </Grid>                         
                            <Grid item xs={12}>
                                <ErrorMessage
                                    errors={errors}
                                    name="singleErrorInput"
                                    render={({ message }) => <p>{message}</p>}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    </Box>
                </Container>
            </form>  
        </DialogContent>
        <DialogActions>
            <Button variant="outlined" onClick={() => handleClose()}>Close</Button>
            <Button variant="contained" type='submit'  form="save-form">Save</Button>
        </DialogActions>
    </Dialog>
    )
  }

  export default ChangePasswordModal;