import { faker } from '@faker-js/faker';
import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import { showError } from '../utils/toast';

import Page from '../components/Page';
import Iconify from '../components/Iconify';

// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------
import {isAuthenticated, getUserName, isStaff} from '../utils/session';
import {apiUrl, apiCallSecureGet} from '../utils/api';

const DashoboadView = ({count}) => {

  if(isStaff()) {
     return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary title="Programs" total={count?.programs} color="info" icon={'fluent-emoji-high-contrast:robot'} />
        </Grid>
      </Grid>
     )
  }

  return (
    <Grid container spacing={3}>

      <Grid item xs={12} sm={6} md={3}>
        <AppWidgetSummary title="" total={count?.students} icon={'gridicons:multiple-users'} />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <AppWidgetSummary title="" total={count?.classes} color="warning" icon={'fa-solid:school'} />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <AppWidgetSummary title="" total={count?.programs} color="info" icon={'fluent-emoji-high-contrast:robot'} />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <AppWidgetSummary title="" total={count?.staff} color="warning" icon={'healthicons:i-training-class'} />
      </Grid>
      
    </Grid>
  )
}

export default function DashboardApp() {
  const theme = useTheme();
  const [counts, setCounts] = useState({
    classes: 0,
    students: 0,
    programs: 0,
    staff: 0,
  });
  const [loadData, setLoadData] = useState(true);

  useEffect(()=>{

    setLoadData(false);

    if(loadData) {
      return;
    }

    apiCallSecureGet(
      apiUrl.dashboard,
      (response) => {
        console.log('response', response)
        if(response.count) {
          setCounts(response.count);
        }
      },
      (error) => {
        showError(error);
      }
    )

  }, [loadData])

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, {getUserName()}. Welcome back!
        </Typography>
        <DashoboadView count={counts} />
      </Container>
    </Page>
  );
}
