import React, { useState, useEffect } from 'react';
import {  Link as RouterLink, useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";

// material
import { Link, Stack, Box, TextField, IconButton, InputAdornment, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
// import { showToast } from '../../../utils/toast';
import { logout, saveUser } from "../../../../utils/session";
import { apiUrl, apiPostUnsecure } from "../../../../utils/api";
import { showError, showSucess } from '../../../../utils/toast';

// ----------------------------------------------------------------------

export default function ForgotPasswordForm() {
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);

    const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
    } = useForm();
    const [error, setError] = useState("");

    async function login(data) {
      setLoading(true);
      apiPostUnsecure(
        apiUrl.forgotPassword,
        data,
        (response) => {
          setLoading(false);
          showSucess(response.msg);
          navigate("/login");
        },
        (errorMsg) => {
          setLoading(false);
          console.log('error', errorMsg)
          showError(errorMsg);
        }
      );
    }

    // if(isAuthenticated()) {
    //   return <Redirect to='/dashboard'  />
    // }
    // console.log("errors", errors);
    // console.log("username", watch("username"));
    // console.log("password", watch("password"));


  const [clearStorage, setClearStorage] = useState(true);

  useEffect(()=> {
    setClearStorage(false);
    logout();
  },[clearStorage])

  return (
    <Box >
      <form id="hook-form" onSubmit={handleSubmit(login)}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="email"
            label="Email address"
            {...register("email", { required: "Enter registered email" })}
          />
        </Stack>
        <LoadingButton sx={{mt:2}} fullWidth size="large" type='submit'  form="hook-form" variant="contained" loading={isLoading} >
          Reset Password
        </LoadingButton>
      </form>
    </Box>
  );
}
