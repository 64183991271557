import React, { useState, useEffect } from 'react';

import MUIDataTable from 'mui-datatables';
import { Link as RouterLink } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  ListItemIcon,
  Box,
} from '@mui/material';


// components
import Page from '../../../components/Page';

const FacilityPage = () => {


  return (
    <Page title="Dashboard">
      <Container>
        Sample Page
      </Container>
    </Page>
  );
};

export default FacilityPage;
