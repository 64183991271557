import React, { useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import DashboardApp from './pages/DashboardApp';

import BlankLayout from './layouts/BlankLayout';
//
import Login from './pages/auth/Login';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import Activate from './pages/auth/Activate';
import NotFound from './pages/Page404';
import Register from './pages/auth/Register';

import Announcement from './pages/main/announcement';
import Greetings from './pages/main/greetings';
import Delays from './pages/main/delays';
import Schedule from './pages/main/schedule';
import Facility from './pages/main/facility';
import Dashboard from './pages/main/dashboard';
import Account from './pages/main/account';
import Settings from './pages/main/settings';
import VideoCall from './pages/main/call';
import RecordPage from './pages/main/records';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'announcement', element: <Announcement /> },
        { path: 'greetings', element: <Greetings /> },
        { path: 'delays', element: <Delays /> },
        { path: 'schedule', element: <Schedule /> },
        { path: 'records', element: <RecordPage /> },
        { path: 'facility', element: <Facility /> },
        { path: 'account', element: <Account /> },
        { path: 'settings', element: <Settings /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'activate', element: <Activate /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '/video',
      element: <BlankLayout />,
      children: [
        { path: 'call', element: <VideoCall /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
